import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div>
            <footer className='footer'>
                Copyright &#169; 2024 Emily Birschner. All right received.
            </footer>
        </div>
    )
}

export default Footer
